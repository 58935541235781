



















































































































































































































.sidebar {
  min-width: 340px;
  min-height: 100vh;
  padding: 1em;
  margin-right: 1em;
  background: #eee;
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1);
}
